<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 热门课程管理 </template>
      <template #input>
        <a-button class="all_boder_btn" @click="relationFilling">新建</a-button>
      </template>
    </HeaderBox>
    <!-- <div class="none" v-if="!tableData.length">暂无数据~</div> -->
    <!-- 配置列表 -->
    <a-table
      :rowKey="(item) => item.courseId"
      :columns="columnss"
      :data-source="tableData"
    >
      <template slot="index" slot-scope="item, row, i">
        <div style="text-align: center">
          {{ (pageNumber - 1) * pageSize + i + 1 }}
        </div>
      </template>
      <template slot="operation" slot-scope="item, row, i">
        <div class="btn_router_link">
          <a @click="deleteData(row.courseId)">删除</a>
        </div>
      </template>
    </a-table>

    <!-- 新建编辑弹窗 -->
    <a-modal
      v-model="relation"
      width="800px"
      :title="form.chiefEditorId ? '编辑热门课程' : '新建热门课程'"
      :centered="true"
      :closable="false"
    >
      <div class="search-box">
        <div class="left">
          <a-input
            class="search-input"
            v-model="productForm.name"
            placeholder="请输入商品名称"
          />
        </div>
        <a-button class="search-btn" type="primary" @click="onProductSearch"
          >搜索</a-button
        >
      </div>
      <div class="table-box">
        <a-table
          class="table-template1"
          :row-selection="{
            columnTitle: '选择',
            selectedRowKeys: selectedProductRowKeys,
            onChange: onSelectProduct,
            columnWidth: '70px',
          }"
          bordered
          :rowKey="(item) => item.courseId"
          :columns="productcolumns"
          :data-source="productData"
          :loading="productLoad"
          @change="onProductPage"
        >
          <template slot="index" slot-scope="item, row, index">
            {{ (productForm.page - 1) * productForm.size + index + 1 }}
          </template>
        </a-table>
      </div>
      <template slot="footer">
        <a-button type="primary" :loading="btnLoad" @click="relationOk"
          >确认</a-button
        >
        <a-button @click="relationCancel">取消</a-button>
      </template>
    </a-modal>

    <!-- 删除弹窗 -->
    <a-modal
      v-model="delate"
      width="530px"
      title="删除"
      @ok="delateOk"
      :centered="true"
      :closable="false"
    >
      <div class="tips">
        删除该配置后，首页热门课程将不会有该内容，是否继续删除？
      </div>
    </a-modal>
  </div>
</template>

<script>
const productcolumns = [
  {
    title: "序号",
    align: "center",
    width: "70px",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品名称",
    ellipsis: true,
    align: "center",
    dataIndex: "courseName",
  },
];
const selectcolumns = [
  {
    align: "center",
    dataIndex: "productName",
    width: 120,
    ellipsis: true,
    colSpan: 2,
    scopedSlots: {
      customRender: "productName",
      title: "num",
    },
  },
  {
    title: "操作",
    align: "center",
    colSpan: 0,
    width: 40,
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
  },
  {
    title: "类型",
    align: "center",
    dataIndex: "productType",
    scopedSlots: {
      customRender: "productType",
    },
  },

  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
const columnss = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "courseName",
  },
  {
    title: "操作",
    align: "center",
    dataIndex: "operation",
    scopedSlots: {
      customRender: "operation",
    },
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: {
    HeaderBox,
  },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      delate: false,
      columnss,
      relation: false,
      selectcolumns,
      productData: [], // 商品列表
      productcolumns, // 商品表头
      productTotal: 0, // 商品数量
      productLoad: false,
      btnLoad: false,
      selectedProductRowKeys: [],
      productArry: [], // 已选商品--暂存
      selectData: [], // 已选商品
      courseName: "",
      productForm: {
        name: "",
        type: undefined,
        page: 1,
        size: 10,
      },
      selectTotal: 0,

      chiefEditorId: 0,
      form: {
        chiefEditorId: "",
        userId: "",
        title: "",
        productIdList: [],
      },

      // 力荐条件
      queryParams: {
        pageNum: 1,
        pageSize: 10,
      },

      ppageNumber: 1, //页码
      ppageSize: 10, // 页数
      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNumber: 1, //页码
      pageSize: 10, // 页数
      expandedRowKeys: [], //选择的id
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage() {},

    // 商品分页
    onProductPage(e) {
      this.productForm.page = e.current;
      this.productForm.size = e.pageSize;
      this.getProductList();
    },
    // 跳详情页
    goDetails() {
      this.$router.push({
        path: "/admin/UserManage/userDetail",
        query: {
          id: 1,
        },
      });
    },

    // 删除单个
    deleteData(e) {
      let _this = this;
      this.$confirm({
        title: "确定删除该标签吗?",
        okText: "确认",
        onOk() {
          _this
            .$ajax({
              url:
                "/hxclass-management/course/deleteHot?courseId=" +
                e +
                "&flag=" +
                0,
              method: "PUT",
            })
            .then((res) => {
              if (res.code == 200 && res.success) {
                _this.$message.success("删除成功");
                _this.delate = false;
                _this.getManageList();
              }
            });
        },
        onCancel() {},
      });
    },
    // 确认添加商品
    relationOk(e) {
      if (!this.selectedProductRowKeys.length) {
        this.$message.warning("请选择课程");
        return;
      }
      // if (!this.selectData.length) {
      //   this.$message.warning("请选择商品");
      //   return;
      // }
      this.btnLoad = true;
      // this.selectData.forEach((element) => {
      //   this.form.productIdList.push(element.productId);
      // });
      let r = this.selectedProductRowKeys.toString();
      this.$ajax({
        method: "put",
        url:
          "/hxclass-management/course/config/hotCourse?courseIds=" +
          r +
          "&flag=" +
          2,
        params: {
          courseIds: r, //课程id集合
          flag: 2, //2是新增
        },
      }).then((res) => {
        this.btnLoad = false;
        if (res.code == 200 && res.success) {
          this.$message.success("添加成功");
          this.getManageList();
          this.relation = false;
        } else {
          this.$message.warning(res.message);
        }
      });
    },

    // 检索力荐列表
    getManageList() {
      this.$ajax({
        method: "get",
        url: "/hxclass-management/course/mange/course",
        params: this.queryParams,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.tableData = res.data;
        } else {
          this.tableData = [];
          this.$message.error(res.message);
        }
      });
    },
    relationCancel(e) {
      this.relation = false;
    },

    relationFilling() {
      this.relation = true;
    },
    delateOk() {
      this.$ajax({
        method: "DELETE",
        url:
          "/hxclass-management/chief/del?chiefEditorId=" + this.chiefEditorId,
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getManageList();
          this.delate = false;
        } else {
        }
      });
    },

    // 商品高级检索
    onProductSearch() {
      this.productForm.page = 1;
      this.getProductList();
    },
    // 检索商品列表
    getProductList() {
      this.productLoad = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/course/course/all",
        params: this.courseName,
      }).then((res) => {
        this.productLoad = false;
        if (res.code == 200 && res.success) {
          this.productTotal = res.data.total;
          this.productData = res.data;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 选择商品
    onSelectProduct(selectedRowKeys, v) {
      this.selectedProductRowKeys = selectedRowKeys;
      this.productArry[this.productForm.page - 1] = v;
      // this.selectData = []
      let storageArry = [];
      this.productArry.forEach((item) => {
        item.forEach((subItem) => {
          storageArry.push(subItem);
        });
      });
      // 已选数组和新数组合并
      this.selectData = [...this.selectData, ...storageArry];
      // console.log(this.selectData)
      // console.log(selectedRowKeys)

      let arry = [];
      selectedRowKeys.forEach((element) => {
        this.selectData.forEach((item) => {
          if (element == item.productId) arry.push(item);
        });
      });

      // 并列数组去重
      let newArr = [];
      let obj = {};
      for (var i = 0; i < arry.length; i++) {
        if (!obj[arry[i].productId]) {
          newArr.push(arry[i]);
          obj[arry[i].productId] = true;
        }
      }

      this.selectData = newArr;
      this.selectTotal = this.selectData.length;
    },

    // 清除某项已选商品
    onSelectIndexDel(index) {
      this.selectData.splice(index, 1);
      this.selectedProductRowKeys.splice(index, 1);
      this.selectTotal = this.selectData.length;
    },

    onEditChange(e) {
      this.selectedProductRowKeys = [];
      this.form.title = e.title;
      this.form.chiefEditorId = e.chiefEditorId;
      let arry = JSON.stringify(e.productVOList);
      this.selectData = JSON.parse(arry);
      e.productVOList.forEach((element) => {
        this.selectedProductRowKeys.push(element.productId);
      });

      this.selectTotal = this.selectData.length;
      this.relation = true;
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    this.getManageList();
    this.getProductList(); // 商品列表
    this.form.userId = this.$store.state.userInfo.id;
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {
    relation() {
      if (!this.relation) {
        this.form.title = "";
        this.form.chiefEditorId = "";
        this.form.productIdList = [];
        this.selectedProductRowKeys = [];
        this.selectData = [];
        this.selectTotal = 0;
        this.productForm.type = undefined;
        this.productForm.page = 1;
        this.productForm.name = "";
      } else {
        this.getProductList(); // 商品列表
      }
    },
  },
};
</script>

<style lang="less" scoped>
.none {
  width: 100%;
  margin: 80px 0;
  font-size: 24px;
  text-align: center;
}
.configs {
  padding-top: 16px;

  .configItem {
    display: flex;
    margin-top: 77px;

    .title {
      width: 67px;
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 45px;
      padding-right: 19px;
    }

    .content {
      .function {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .ant-input {
          width: 464px;
          height: 45px;
        }

        .ant-btn {
          margin-left: 24px;
        }
      }

      // .operations {
      //   display: flex;
      //   justify-content: center;

      //   .operation {
      //     padding: 0 5px;
      //     border-left: 1px solid #3681f0;
      //     font-size: 12px;
      //     font-family: PingFang SC-Regular, PingFang SC;
      //     font-weight: 400;
      //     color: #3681f0;
      //     line-height: 12px;
      //   }

      //   .operation:first-child {
      //     border: 0;
      //   }
      // }
    }
  }

  .configItem:first-child {
    margin: 0;
  }
}

.btns {
  margin-left: 67px;
  margin-top: 64px;
  padding-bottom: 58px;

  .ant-btn {
    margin-right: 19px;
  }
}

.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
}

/deep/.ant-modal-body {
  overflow: hidden;
}

/deep/.ant-modal-title {
  text-align: center;
  color: #000000;
}

/deep/.ant-modal-header {
  border-bottom: none;
}

/deep/.ant-modal-footer {
  text-align: center;
  border-top: none;
}

/deep/ .ant-modal-body {
  padding: 14px 36px 14px;
}

/deep/.ant-tabs-ink-bar-animated {
  position: relative;
  top: -10px;
}

.tips {
  font-size: 16px;
  color: #000000;
  text-align: center;
}

.table-template {
  /deep/.ant-table {
    border: none;
  }
}

.table-box {
  display: flex;
  justify-content: space-between;
  .table-template1 {
    width: 600px;
    margin-right: 20px;
  }
  .table-template2 {
    flex: 1;
  }
}

.name-box {
  display: flex;
  align-items: center;
  margin: 14px 0;
  .name {
    width: 60px;
  }
  // width: 200px;
}

.search-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 640px;
  .left {
    width: 90%;
    display: flex;
    align-items: center;
    > span {
      display: inline-block;
      width: 100px;
    }
    .search-input {
      width: 36%;
      margin-right: 20px;
    }
  }
}
</style>
